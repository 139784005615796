/* WCAG font */

$_COOKIE = {};
eval((document.cookie + ';').replace(/([\w%]+)=?([^;]*);/g, "$$_COOKIE['$1']='$2';"));

function setCookieF(name, value, expires, path, domain, secure) {
    document.cookie = name + '=' + escape(value || '') + ( expires ? ';expires=' + new Date(+new Date() + expires * 864e5).toGMTString() : '') + "; path=/" + ( domain ? ';domain=' + domain : '') + ( secure ? ';secure' : '');
}

function setFontSizeCookie(size) {
    //alert(size);
    setCookieF('fontSize', size);
    $_COOKIE.fontSize = size;
    reSize(size);
    setFontSize();
    return false;
}

function setFontSize() {
    if ($_COOKIE.fontSize != "undefined") {
        document.documentElement.className = $_COOKIE.fontSize;
        var size = $_COOKIE.fontSize;
        reSize(size);
    }
}


function reSize(size) {
    if (size == 'big_f') {
        $('html').removeClass('small_f'); 
        $('.btn_sprite--small').removeClass('active');
        $('html').removeClass('normal_f'); 
        $('.btn_sprite--medium').removeClass('active');
        $('html').addClass('big_f');
        $('.btn_sprite--high').addClass('active');
    }
    if (size == 'normal_f') {
        $('html').removeClass('small_f'); 
        $('.btn_sprite--small').removeClass('active');
        $('html').removeClass('big_f'); 
        $('.btn_sprite--high').removeClass('active');
        $('html').addClass('normal_f');
        $('.btn_sprite--medium').addClass('active');
    }
    if (size == 'small_f') {
        $('html').removeClass('normal_f'); 
        $('.btn_sprite--medium').removeClass('active');
        $('html').removeClass('big_f'); 
        $('.btn_sprite--high').removeClass('active');
        $('html').addClass('small_f');
        $('.btn_sprite--small').addClass('active');
    }
}

if (!document.addEventListener) {
    document.attachEvent("DOMContentLoaded", setFontSize);
} else {
    document.addEventListener("DOMContentLoaded", setFontSize, false);
}

/* Menu left */
$('.navbar-toggle').click(function(){
$('body').toggleClass('shift');
$('.menu_left').toggleClass('shift');
$('.navbar-toggle').toggleClass('shift');
$('.icon-bar').toggleClass('cont');
$('.blur').toggleClass('shift');
});

